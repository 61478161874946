<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row mb-5">
      <div class="col-12">
        <h2>{{ worker.firstName }} {{ worker.lastName }}</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4">
        <div class="card card-custom">
          <div class="card-body py-4">
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.ADDRESS") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext">
                  <span class="font-weight-bolder">{{ worker.address }}</span>
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.OIB") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">{{
                  worker.oib
                }}</span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.PASSPORT") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  {{ worker.passport }}
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.MOBILE") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  {{ worker.mob }}
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.EMAIL") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  {{ worker.email }}
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.FATHERS_NAME") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  {{ worker.fathersName }}
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.MOTHERS_NAME") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  {{ worker.mothersName }}
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.SALARY") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  {{ worker.wage | currencyFormat }}
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.WORK_PERMIT_EXPIRATION_DATE") }}:</label
              >
              <div class="col-6 d-flex align-items-center">
                <span
                  class="form-control-plaintext font-weight-bolder py-2"
                  :class="{ 'text-danger': isWorkPermitDateExpired }"
                >
                  {{ worker.workPermitDate | formatDate }}
                </span>
                <span
                  v-if="isWorkPermitDateExpired"
                  class="label label-lg label-light-danger label-inline"
                  >{{ $t("GENERAL.EXPIRED") }}</span
                >
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.MEDICAL_EXPIRATION_DATE") }}:</label
              >
              <div class="col-6 d-flex align-items-center">
                <span
                  class="form-control-plaintext font-weight-bolder py-2"
                  :class="{ 'text-danger': isPhysicalExamDateExpired }"
                >
                  {{ worker.physicalExamDate | formatDate }}
                </span>
                <span
                  v-if="isWorkPermitDateExpired"
                  class="label label-lg label-light-danger label-inline"
                  >{{ $t("GENERAL.EXPIRED") }}</span
                >
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.WORK_SAFETY") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  <span
                    v-if="worker.workProtection"
                    class="label label-lg label-light-success label-inline"
                    >{{ $t("GENERAL.YES") }}</span
                  >
                  <span
                    v-else
                    class="label label-lg label-light-danger label-inline"
                    >{{ $t("GENERAL.NO") }}</span
                  >
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.FIRST_AID") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  <span
                    v-if="worker.firstAid"
                    class="label label-lg label-light-success label-inline"
                    >{{ $t("GENERAL.YES") }}</span
                  >
                  <span
                    v-else
                    class="label label-lg label-light-danger label-inline"
                    >{{ $t("GENERAL.NO") }}</span
                  >
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.GEDA") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  <span
                    v-if="worker.geda"
                    class="label label-lg label-light-success label-inline"
                    >{{ $t("GENERAL.YES") }}</span
                  >
                  <span
                    v-else
                    class="label label-lg label-light-danger label-inline"
                    >{{ $t("GENERAL.NO") }}</span
                  >
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.BORROWED_TOOLS") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  {{ worker.tools }}
                </span>
              </div>
            </div>
            <div class="form-group row my-1">
              <label class="col-6 col-form-label py-2"
                >{{ $t("GENERAL.NOTE") }}:</label
              >
              <div class="col-6">
                <span class="form-control-plaintext font-weight-bolder py-2">
                  {{ worker.note }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap border-0 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("GENERAL.HOURLY_RATE") }}
                <span v-if="range">From : {{ range.start | formatDate }}</span>
                <span v-if="range">
                  To :
                  {{ range.end | formatDate }}</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <vc-date-picker
                @input="onRangeSelect"
                is-range
                :columns="2"
                v-model="range"
                :masks="masks"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <span
                    @click="togglePopover()"
                    class="symbol symbol-40 symbol-light-primary symbol-hover cursor-pointer mr-3 animate__animated animate__fadeIn"
                  >
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg src="media/svg/icons/Media/Playlist2.svg" />
                      </span>
                    </span>
                  </span>
                </template>
              </vc-date-picker>
              <!--              <vc-date-picker-->
              <!--                v-model="range"-->
              <!--                mode="date"-->
              <!--                :masks="masks"-->
              <!--                is-range-->
              <!--                @input="onRangeSelect"-->
              <!--              >-->
              <!--                <template v-slot="{ inputValue, inputEvents, isDragging }">-->
              <!--                  <div class="d-flex">-->
              <!--                    <div class="input-group">-->
              <!--                      <div class="input-group-prepend">-->
              <!--                        <span class="input-group-text"-->
              <!--                          ><i class="la la-calendar"></i-->
              <!--                        ></span>-->
              <!--                      </div>-->
              <!--                      <input-->
              <!--                        class="form-control"-->
              <!--                        :value="inputValue.start"-->
              <!--                        v-on="inputEvents.start"-->
              <!--                      />-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </template>-->
              <!--              </vc-date-picker>-->
              <!--              <div class="ml-2">-->
              <!--                <button-->
              <!--                  type="button"-->
              <!--                  class="btn btn-light-primary font-weight-bolder"-->
              <!--                  data-toggle="dropdown"-->
              <!--                  aria-haspopup="true"-->
              <!--                  aria-expanded="false"-->
              <!--                  disabled-->
              <!--                >-->
              <!--                  <span class="svg-icon svg-icon-md">-->
              <!--                    <inline-svg src="media/svg/icons/Files/Export.svg" />-->
              <!--                  </span>-->
              <!--                  Export to .xlsx-->
              <!--                </button>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center mb-0"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 120px" class="pl-7">
                          <span>{{ $t("GENERAL.DATE") }}</span>
                        </th>
                        <th style="min-width: 120px">
                          <span>{{ $t("GENERAL.CONSTRUCTION_SITE") }}</span>
                        </th>
                        <th style="min-width: 120px" class="pr-7 text-right">
                          {{ $t("GENERAL.WORK_HOURS") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(hourlyRate, key) in worker.hourlyRates"
                        :key="key"
                      >
                        <td
                          class="pl-0 py-4 pl-7"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span>{{ hourlyRate.date | formatDate }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            class="font-weight-bolder text-primary cursor-pointer mb-0"
                            >{{ hourlyRate.construction.name }}</span
                          >
                        </td>
                        <td
                          class="pr-7 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span v-if="hourlyRate.workHours"
                            >{{ hourlyRate.workHours }}h</span
                          >
                          <span v-else>-</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  v-if="isTotalHourlyRatesLoaded"
                  class="d-flex align-items-center pt-7 pl-7"
                >
                  <strong class="font-size-lg mr-2"
                    >{{ $t("GENERAL.TOTAL") }}:
                  </strong>
                  <div
                    class="label label-inline label-xl label-light-primary font-weight-bold mr-3"
                  >
                    <strong class="mr-2">{{ $t("GENERAL.WORK_HOURS") }}:</strong
                    >{{ totalHourlyRates.workHours }}h
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row d-flex">
              <button
                @click.prevent="addNewHourlyRate"
                class="btn btn-light-primary font-weight-bold py-2 px-5 ml-5"
              >
                <i class="ki ki-plus icon-sm"></i>
                {{ $t("GENERAL.ADD_HOURLYRATE") }}
              </button>
              <button
                v-if="hourlyRates.length > 0"
                @click.prevent="saveHourlyRates"
                class="btn btn-primary font-weight-bold py-2 px-5 ml-auto"
              >
                <i class="ki ki-check icon-sm"></i>
                {{ $t("GENERAL.SAVE_HOURLYRATE") }}
              </button>
            </div>
            <ValidationObserver
              v-if="hourlyRates.length > 0"
              ref="observerRef"
              v-slot="{ handleSubmit }"
            >
              <form
                class="form"
                @submit.prevent="handleSubmit(saveHourlyRates)"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="separator separator-dashed mt-8 mb-10"></div>
                    <div
                      v-for="(_, key) in hourlyRates"
                      :key="key"
                      :id="'new-hourly-rate-' + key"
                    >
                      <div class="row">
                        <div class="col-md-3">
                          <validation-provider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label
                                >{{ $t("GENERAL.DATE") }}
                                <span class="text-danger">*</span></label
                              >
                              <vc-date-picker
                                v-model="hourlyRates[key].date"
                                :masks="masks"
                              >
                                <template
                                  v-slot="{ inputValue, togglePopover }"
                                >
                                  <div
                                    class="input-group"
                                    @click="togglePopover()"
                                  >
                                    <input
                                      id="date"
                                      class="form-control"
                                      :class="{ 'is-invalid': errors[0] }"
                                      :value="inputValue"
                                      name="date"
                                      type="text"
                                    />
                                    <div
                                      class="input-group-prepend cursor-pointer"
                                    >
                                      <span class="input-group-text"
                                        ><i class="la la-calendar"></i
                                      ></span>
                                    </div>
                                  </div>
                                </template>
                              </vc-date-picker>
                            </div>
                          </validation-provider>
                        </div>

                        <div class="col-md-6">
                          <validation-provider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="form-group">
                              <label
                                >{{ $t("GENERAL.CONSTRUCTION_SITE") }}
                                <span class="text-danger">*</span></label
                              >
                              <v-select
                                class="form-control form-control--construction-select"
                                :class="{ 'is-invalid': errors[0] }"
                                placeholder="Odaberite gradilište"
                                v-model="hourlyRates[key].construction"
                                :options="constructions"
                                :reduce="(construction) => construction['@id']"
                                label="name"
                              >
                              </v-select>
                            </div>
                          </validation-provider>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label>{{ $t("GENERAL.WORK_HOURS") }}:</label>
                            <select
                              class="form-control"
                              v-model="hourlyRates[key].workHours"
                            >
                              <option value="00:00">00:00</option>
                              <option value="00:30">00:30</option>
                              <option value="01:00">01:00</option>
                              <option value="01:30">01:30</option>
                              <option value="02:00">02:00</option>
                              <option value="02:30">02:30</option>
                              <option value="03:00">03:00</option>
                              <option value="03:30">03:30</option>
                              <option value="04:00">04:00</option>
                              <option value="04:30">04:30</option>
                              <option value="05:00">05:00</option>
                              <option value="05:30">05:30</option>
                              <option value="06:00">06:00</option>
                              <option value="06:30">06:30</option>
                              <option value="07:00">07:00</option>
                              <option value="07:30">07:30</option>
                              <option value="08:00">08:00</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12 d-flex mt-3">
                          <button
                            @click.prevent="removeHourlyRate(key)"
                            class="btn btn-danger font-weight-bold py-2 px-5 ml-auto"
                          >
                            <i class="ki ki-close icon-sm"></i> Remove
                          </button>
                        </div>
                      </div>

                      <div class="separator separator-dashed my-10"></div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { isPast, startOfMonth, format } from "date-fns";
import vSelect from "vue-select";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "",
});

export default {
  name: "ViewWorker",
  components: { vSelect, ValidationProvider, ValidationObserver },
  data() {
    return {
      worker: {
        firstName: "",
        lastName: "",
        address: "",
        oib: "",
        email: "",
        mob: "",
        passport: "",
        fathersName: "",
        mothersName: "",
        workPermitDate: "",
        physicalExamDate: "",
        workProtection: true,
        firstAid: true,
        geda: true,
        overtimeHr: null,
        weekendHr: null,
        dailyWage: null,
        wage: null,
        nightHr: null,
        note: "",
        tools: "",
        hourlyRates: [],
      },
      isWorkerLoaded: false,
      isWorkPermitDateExpired: false,
      isPhysicalExamDateExpired: false,
      hourlyRates: [],
      constructions: [],
      isConstructionsLoaded: false,
      totalHourlyRates: null,
      isTotalHourlyRatesLoaded: false,
      masks: {
        input: "DD.MM:YYYY.",
      },
      range: {
        start: startOfMonth(new Date()),
        end: new Date(),
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "All Workers", route: "/workers/list" },
      { title: "View Worker" },
    ]);

    ApiService.get("workers/", this.$route.params.id).then(({ data }) => {
      this.worker.firstName = data.firstName;
      this.worker.lastName = data.lastName;
      this.worker.address = data.address;
      this.worker.oib = data.oib;
      this.worker.email = data.email;
      this.worker.mob = data.mob;
      this.worker.passport = data.passport;
      this.worker.fathersName = data.fathersName;
      this.worker.mothersName = data.mothersName;
      this.worker.workPermitDate = data.workPermitDate;
      this.worker.physicalExamDate = data.physicalExamDate;
      this.worker.workProtection = data.workProtection;
      this.worker.firstAid = data.firstAid;
      this.worker.geda = data.geda;
      this.worker.overtimeHr = data.overtimeHr;
      this.worker.weekendHr = data.weekendHr;
      this.worker.dailyWage = data.dailyWage;
      this.worker.wage = data.wage;
      this.worker.nightHr = data.nightHr;
      this.worker.note = data.note;
      this.worker.tools = data.tools;

      if (isPast(new Date(this.worker.workPermitDate)))
        this.isWorkPermitDateExpired = true;
      if (isPast(new Date(this.worker.physicalExamDate)))
        this.isPhysicalExamDateExpired = true;

      ApiService.get(
        `hourly_rates/${this.$route.params.id}/total/${format(
          this.range.start,
          "yyyy-MM-dd"
        )}/${format(this.range.end, "yyyy-MM-dd")}`
      ).then(({ data }) => {
        this.worker.hourlyRates = data["hydra:member"];
        this.totalHourlyRates = data["total"];
        this.isTotalHourlyRatesLoaded = true;
      });

      ApiService.get("constructions").then(({ data }) => {
        this.constructions = data["hydra:member"];
        this.isConstructionsLoaded = true;
      });

      this.isWorkerLoaded = true;
    });
  },
  methods: {
    onRangeSelect() {
      this.isTotalHourlyRatesLoaded = false;
      ApiService.get(
        `hourly_rates/${this.$route.params.id}/total/${format(
          this.range.start,
          "yyyy-MM-dd"
        )}/${format(this.range.end, "yyyy-MM-dd")}`
      ).then(({ data }) => {
        this.worker.hourlyRates = data["hydra:member"];
        this.totalHourlyRates = data["total"];
        this.isTotalHourlyRatesLoaded = true;
      });
    },
    addNewHourlyRate() {
      this.hourlyRates.push({
        workHours: null,
        overtimeHr: null,
        weekendHr: null,
        dailyWage: null,
        nightHr: null,
        date: new Date(),
        worker: "/api/v1/workers/" + this.$route.params.id,
        construction: null,
      });
      setTimeout(() => {
        document
          .getElementById("new-hourly-rate-" + (this.hourlyRates.length - 1))
          .scrollIntoView({ behavior: "smooth", block: "end" });
      }, 200);
    },
    removeHourlyRate(key) {
      let confirmation = confirm("Zelite izbrisati?");
      if (confirmation === false) return;

      this.hourlyRates.splice(key, 1);
    },
    saveHourlyRates() {
      this.$refs.observerRef.validate().then((isValid) => {
        if (isValid) {
          this.hourlyRates.forEach((hourlyRate) => {
            ApiService.post("hourly_rates", hourlyRate)
              .then(() => {
                this.hourlyRates = [];
                this.isWorkerLoaded = false;
                this.isTotalHourlyRatesLoaded = false;
                ApiService.get(
                  `hourly_rates/${this.$route.params.id}/total/${format(
                    this.range.start,
                    "yyyy-MM-dd"
                  )}/${format(this.range.end, "yyyy-MM-dd")}`
                ).then(({ data }) => {
                  this.worker.hourlyRates = data["hydra:member"];
                  this.totalHourlyRates = data["total"];
                  this.isTotalHourlyRatesLoaded = true;
                });
              })
              .catch((error) => {
                this.$notify({
                  group: "notification",
                  type: "error",
                  title: error,
                });
              });
          });
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Please enter all mandatory fields",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
